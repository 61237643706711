
@font-face {
  font-family: 'z-social';
  src: url('../fonts/z-social.eot');
  src: url('../fonts/z-social.eot') format('embedded-opentype'), url('../fonts/z-social.woff') format('woff'), url('../fonts/z-social.ttf') format('truetype'), url('../fonts/z-social.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="zso-"], [class*=" zso-"], [class^="zs-link-"]:after, [class*=" zs-link-"]:after, [class^="zs-ico-"]:before, [class*=" zs-ico-"]:before, ._zsIcon:before, ._zsIcon:after, .searchContainer:before, .socialDisplaySearch:before, .nextOff:before, .next:before, .prevOff:before, .prev:before, .zs-checkbox input[type=checkbox]+label:before, .zs-checkbox-fill input[type=checkbox]+label:before, .inviteUserList input[type=checkbox]+label i.checkbox:before, .columnchooser label input[type=checkbox]+i.checkbox:before, .zs-radio input[type=radio]+label:before, .inviteUserList input[type=radio]+label i.radio:before, ._pinboards .pinboard-selected:after, .bestslots input[type=radio]+label div.radio:before, .npImg-radio input[type=radio]+label, .npImg-radio input[type=radio]:checked+label:before, .fsOpt-inp:after, .goMore:before, .userinfopanel-portallist a.active:before, ._profiletag .postmention-profilelist a.selected:before, #toTop:before, .zs-radio-tick input[type=radio]+label:before, .userpicker:after, .userpicker-list a.selected:after, .quickmenu._select a:before, .normalList._select a:before, ._collab-type:before, .wms-gssearch .wms-gssearchico:before, .retweetedIcon:before, .usertagging-list .streamAvatar:before, .twPrivateMsg:before, .select2-container .select2-selection__arrow b:before, .bestslots.selected:after {
  font-family: 'z-social';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
}

[class^="zs-ico-"]:before, [class*=" zs-ico-"]:before {
  display: inline-block;
  padding-right: 3px;
  font-size: 14px;
  line-height: 17px;
  position: absolute;
  left: 0;
  top: 1px;
  color: #757575;
}

[class^="zs-ico-"].black:before, [class*=" zs-ico-"].black:before, a[class^="zs-ico-"]:hover:before, a[class*=" zs-ico-"]:hover:before {
  color: #000000;
}

.storyFooter [class^="zs-ico-"]:before, .storyFooter [class*=" zs-ico-"]:before {
  color: #888888;
}

.searchContainer:before {
  content: "\e60b";
  font-size: 14px;
  display: block;
  color: #505050;
  position: absolute;
  top: 16px;
  left: 15px;
  line-height: 0;
}

.socialDisplaySearch:before {
  content: "\e60b";
  font-size: 20px;
  display: inline-block;
  color: #999999;
}

._pinboards .pinboard-selected:after {
  content: "\e618";
  display: block;
  position: absolute;
  float: right;
  top: 15px;
  right: 11px;
  color: #888888;
  font-size: 12px;
}

._pinboards .pinboard-selected:hover:after {
  color: #000000;
}


.zso-back:before {
  content: "\e612";
}

.zso-arrow-previous:before {
  content: "\f104";
}

.zso-arrow-next:before {
  content: "\f105";
}

.zso-mail:before {
  content: "\e605";
}

.zso-bell:before {
  content: "\e609";
}

.zso-statistics:before {
  content: "\e619";
}

.zso-monitor:before {
  content: "\f085";
}

.zso-collaborate:before, ._collab-type._DISCUSSION:before {
  content: "\e608";
}

.zso-bars:before {
  content: "\e60d";
}

.zso-pie:before {
  content: "\e60a";
}

.zso-table:before {
  content: "\e610";
}

.zso-create:before, ._collab-type._DRAFT:before {
  content: "\e602";
}

.zso-search:before, .wms-gssearch .wms-gssearchico:before, .searchField:before {
  content: "\e60b";
}

.zso-article:before {
  content: "\e606";
}

.zso-star:before {
  content: "\e60c";
}

.zso-list:before {
  content: "\e60f";
}

.zso-arrow-down:before, button.dropDownBtn:after, div.dropDownBtn:after, .select2-container .select2-selection__arrow b:before, .videoStatus._dropdown:after, ._npDropDown:after {
  content: "\e607";
}

.zso-arrow-drop-down:before, .zs-link-dropdown:after, .userpicker:after, .sharedwith:after, .dropdownLink:after, .viewTitle:after {
  content: "\e60e";
}

.zso-megaphone:before {
  content: "\e615";
}

.zso-publish:before, ._collab-type._POST:before {
  content: "\e601";
}

.zso-users:before, ._collab-type._CONNECTION:before {
  content: "\e604";
}

.zso-stats:before {
  content: "\e603";
}

.zso-home:before {
  content: "\e600";
}

.zso-sort-alphabetically:before {
  content: "\e614";
}

.zso-calendar:before {
  content: "\e611";
}

.zso-calendar-filter:before {
  content: "\e978";
}

.zso-gear:before {
  content: "\f013";
}

.zso-close:before, .zso-stop:before, ._profiletag .postmention-profilelist a.selected:hover:before, ._showpopup .zso-filter-line:before, .zso-kfclose:before, i._collab-type._REJECTED:before {
  content: "\e618";
}

.zso-check:before, ._profiletag .postmention-profilelist a.selected:before, .userpicker-list a.selected:after, .quickmenu._select a:before, .sharewith-menu a.selected:after, .normalList._select a.selected:before, .bulkImgAttachment a.selected:after, .sharewith-list a:after, i._collab-type._APPROVAL:before, i._collab-type._APPROVED:before, .usertagging-list._selected .streamAvatar:before, .usertagging-list a:hover .streamAvatar:before, .bestslots.selected:after {
  content: "\e61a";
}

.zso-exclamation:before {
  content: "\e617";
}

.zso-circle-up:before {
  content: "\ea41";
}

.zso-circle-down:before {
  content: "\ea43";
}

.zso-cancel:before {
  content: "\e613";
}

.zso-globe:before {
  content: "\f0b6";
}

.zso-help-with-circle:before {
  content: "\e61b";
}

.zso-camera:before {
  content: "\f030";
}

.zso-arrow-left:before {
  content: "\e61c";
}

.zso-arrow-right:before, .zso-playicon:before {
  content: "\e61d";
}

.zso-menu:before {
  content: "\e61e";
}

.zso-calendar2:before {
  content: "\e616";
}

.zso-arrow-drop-up:before {
  content: "\e61f";
}

.zso-sort:before {
  content: "\e621";
}

.zso-long-arrow-down:before {
  content: "\f175";
}

.zso-location:before, .zs-ico-location:before {
  content: "\f060";
}

.zso-android:before {
  content: "\e622";
}

.zso-apple:before {
  content: "\e620";
}

.zso-lock:before {
  content: "\f023";
}

.zso-lock-line:before {
  content: "\e94c";
}

.zs-checkbox input[type=checkbox]+label:before, .inviteUserList input[type=checkbox]+label i.checkbox:before, .columnchooser label input[type=checkbox]+i.checkbox:before {
  content: "\e944";
}

.zs-checkbox input[type=checkbox]:checked+label:before, .inviteUserList input[type=checkbox]:checked+label i.checkbox:before, .columnchooser label input[type=checkbox]:checked+i.checkbox:before {
  content: "\e945";
}

.zs-checkbox-fill input[type=checkbox]+label:before {
  content: "\e92f";
}

.zs-checkbox-fill input[type=checkbox]:checked+label:before {
  content: "\e931";
}

.zso-edit:before {
  content: "\e623";
}

.zso-trash:before {
  content: "\f1f8";
}

.zso-refresh:before {
  content: "\e211";
}

.zso-refresh-line:before {
  content: "\e939";
  font-weight: bold;
  font-size: 20px;
}

.zso-plus:before {
  content: "\e069";
}

.zs-radio input[type=radio]+label:before, .inviteUserList input[type=radio]+label i.radio:before, .bestslots input[type=radio]+label div.radio:before {
  content: "\e9ee";
}

.zs-radio input[type=radio]:checked+label:before, .inviteUserList input[type=radio]:checked+label i.radio:before, .bestslots input[type=radio]:checked+label div.radio:before {
  content: "\e9ed";
}

.zso-agreement:before {
  content: "\e800";
}

.zso-office:before {
  content: "\e801";
}

.zso-phone:before {
  content: "\e802";
}

.zso-mobile:before {
  content: "\e803";
}

.zso-owner:before {
  content: "\e804";
}

.zso-info:before {
  content: "\e900";
}

.zso-file-doc:before {
  content: "\f15c";
}

.zso-heart:before {
  content: "\f004";
}

.zso-user:before, .zs-ico-leads:before {
  content: "\e901";
}

.zso-filter:before {
  content: "\f0b0";
}

.zso-arrow_back:before {
  content: "\e200";
}

.zso-more-dots:before, .zso-moreOpt:before, .goMore:before, .zs-ico-dots:before {
  content: "\e904";
}

.zs-ico-dots:before {
  font-size: 16px;
}

.zso-twitter:before {
  content: "\e906";
  color: #55acee;
}

.zso-android-new:before {
  content: "\e622";
}

.zso-apple-new:before {
  content: "\e620";
}

.zso-playstore:before {
  content: "\e9b8";
}

.zso-chrome:before {
  content: "\e902";
}

.zso-firefox:before {
  content: "\e903";
}

.zso-addon:before {
  content: "\e908";
}

.zso-opera:before {
  content: "\e947";
}

.zso-safari:before {
  content: "\e968";
}

.zso-feedback:before {
  content: "\e94a";
}

.zso-dashboard:before {
  content: "\e90a";
}

.zso-clock:before, ._collab-type._SCHEDULED:before {
  content: "\e90b";
}

.zso-arrows:before {
  content: "\e907";
}

.zso-vcard:before {
  content: "\e909";
}

.zso-language:before {
  content: "\e90e";
}

.zso-clock-bulk:before {
  content: "\e90f";
}

.zso-clock-smartq:before {
  content: "\e910";
}

.zso-clock-timewarp:before {
  content: "\e90d";
}

.zso-repeat:before {
  content: "\e907";
}

.zso-addphoto:before {
  content: "\e905";
  font-size: 16px;
}

.zso-warning:before {
  content: "\e913";
}

.zso-discuss:before {
  content: "\e914";
}

.zso-spinner:before {
  content: "\e915";
}

.zso-profile:before {
  content: "\e916";
}

.zso-interactions:before, .zso-twConversation:before {
  content: "\e049";
}

.zso-fb-recent:before {
  content: "\e05d";
}

.zso-tw-recent:before {
  content: "\e05e";
}

.zso-gp-recent:before {
  content: "\e05f";
}

.zso-embed:before {
  content: "\e918";
}

.zso-user-filter:before {
  content: "\e917";
}

.zso-arrow-down-line:before, #toTop:before, .collab-inpOpt._sharewith:after, .kcolfilter-header:after, .reports-listheader:after {
  content: "\e919";
}

.zso-back-arrow:before {
  content: "\e91a";
  cursor: pointer;
  font-weight: bold;
}

.zso-pause:before {
  content: "\e91f";
}

.zso-flag:before {
  content: "\e920";
}

.zso-linecamera:before {
  content: "\e91d";
}

.zso-back-line:before {
  content: "\e923";
}

.zso-next-line:before {
  content: "\e924";
}

.zso-draft:before {
  content: "\e925";
}

.zso-target:before {
  content: "\e926";
}

.zso-twdm:before, .twPrivateMsg:before {
  content: "\ea05";
}

.zso-tw-dm:before {
  content: "\ea04";
}

.zso-reorder:before {
  content: "\e928";
}

.npImg-radio input[type=radio]+label:before {
  content: "\e91b";
}

.npImg-radio input[type=radio]:checked+label:before {
  content: "\e91c";
}

.zs-radio-tick input[type=radio]+label:before {
  content: "\e966";
}

.zs-radio-tick input[type=radio]:checked+label:before {
  content: "\e967";
}

.zso-clone:before {
  content: "\e929";
}

.fsOpt-inp:after {
  content: "\e61e";
}

.zso-image:after {
  content: "\e92b";
}

.zso-link:after {
  content: "\e92a";
}

.zso-pencil:after {
  content: "\e92d";
}

.zso-trash-line:after {
  content: "\e92c";
}

.zso-info-line:after {
  content: "\e92e";
}

.zso-mention:after {
  content: "\e930";
}

.zso-question:after {
  content: "\e932";
}

.zso-preference:after {
  content: "\e933";
}

.zso-idea:after {
  content: "\e934";
}

.zso-besttime:after {
  content: "\e935";
}

.zso-clock-line:before, .zs-ico-clock-line:before {
  content: "\e936";
}

.zso-share-line:before, .zs-ico-share-line:before {
  content: "\e937";
}

.zso-grid:before {
  content: "\e938";
}

.zso-grid-chart:before {
  content: "\e93a";
}

.zso-grid-twitter:before, .zso-twitter-line:before {
  content: "\e93b";
}

.zso-grid-summary:before {
  content: "\e93c";
}

.zso-grid-megaphone:before {
  content: "\e93d";
}

.zso-grid-people:before {
  content: "\e93e";
}

.zso-grid-camera:before {
  content: "\e93e";
}

.zso-grid-dashboard:before {
  content: "\e93f";
}

.zso-grid-flag:before {
  content: "\e949";
}

.zso-export:before {
  content: "\e9eb";
}

.zso-mail-line:before {
  content: "\e940";
  font-weight: bold;
}

.zso-bar-chart-line:before {
  content: "\e941";
}

.zso-pie-chart-line:before {
  content: "\e942";
}

.zso-unlink:before {
  content: "\e943";
}

.zso-kgrid:before {
  content: "\e946";
}

.zs-ico-comment:before {
  content: "\e9d4";
}

.zs-ico-heart:before, .zso-like-line:before {
  content: "\e9d5";
}

.zs-ico-heart-selected:before, .zs-ico-heart.selected:before {
  content: "\e9d3";
  color: #e2264d !important;
}

.zs-ico-reply:before {
  content: "\e9da";
}

.zs-ico-retweet:before, .zso-retweet:before {
  content: "\e9d7";
}

.zs-ico-retweet.selected:before {
  content: "\e9d6";
}

.zs-ico-share:before, .zso-share:after {
  content: "\e956";
}

.zs-ico-thumb:before {
  content: "\e9d9";
  top: 0px;
}

.zso-thumb-line:before {
  content: "\e9d9";
}

.zs-ico-thumb.selected:before {
  content: "\e9d8";
}

.zs-ico-plusone:before {
  content: "\e9de";
}

.zs-ico-inreply:before {
  content: "\e90e";
}

.zs-ico-attachments:before, .zso-attach-file:before {
  content: "\e960";
}

.zs-ico-eye:before, .zso-eye:before {
  content: "\e9d2";
}

.zs-ico-click:before, .zso-click:before {
  content: "\e9d1";
}

.zso-positive-sentiment:before {
  content: "\e958";
  font-size: 16px;
  color: #7bb246;
}

.zso-negative-sentiment:before {
  content: "\e95a";
  font-size: 16px;
  color: #D4060A;
}

.zso-neutral-sentiment:before {
  content: "\e959";
  font-size: 16px;
  color: #ffb469;
}

.zso-unknown-sentiment:before {
  content: "\e959";
  font-size: 16px;
  color: #999999;
}

.zso-retweeted:before, .retweetedIcon:before {
  content: "\e948";
  color: #45c181;
  font-size: 15px;
}

.zso-addcolumns:before {
  content: "\e952";
}

.zso-expandwiz:before {
  content: "\e95b";
}

.zso-feedly:before {
  content: "\e95c";
}

.zso-attach:before {
  content: "\e95d";
}

.zso-emoji:before {
  content: "\e958";
}

.zso-download:before {
  content: "\e961";
}

.zso-add-users:before {
  content: "\e962";
}

.zso-crm:before {
  content: "\e963";
}

.zso-shorten-link:before {
  content: "\e965";
}

.zso-close-min:before {
  content: "\e969";
}

.zso-setup-line:before {
  content: "\e96a";
}

.zso-mute:before {
  content: "\e96c";
}

.zso-block:before {
  content: "\e96b";
}

.zso-time:before {
  content: "\e96d";
}

.zso-FAQ:before {
  content: "\e970";
}

.zso-journals:before {
  content: "\e972";
}

.zso-product-guide:before {
  content: "\e974";
}

.zso-whats-new:before {
  content: "\e975";
}

.zso-discussion-forum:before {
  content: "\e96e";
}

.zso-free-webinar:before {
  content: "\e971";
}

.zso-help:before {
  content: "\e96f";
}

.zso-user-all:before, .sharewith-menu a._userall:before, .collab-inpOpt._userall:before {
  content: "\e976";
}

.zso-user-client:before, .sharewith-menu a._userclient:before, .collab-inpOpt._userclient:before {
  content: "\e977";
}

.zso-user-custom:before, .sharewith-menu a._usercustom:before, .collab-inpOpt._usercustom:before {
  content: "\e97a";
}

.zso-user-role:before {
  content: "\e97c";
}

.zso-user-team:before, .sharewith-menu a._userteam:before, .collab-inpOpt._userteam:before {
  content: "\e97d";
}

.zso-plus-line:before {
  content: "\e97e";
}

.zso-close-line:before {
  content: "\e97f";
}

.zso-circle-up-line:before {
  content: "\e95e";
}

.zso-quotes:before {
  content: "\e979";
}

.zso-filter-line:before, .kcolfilter:before {
  content: "\e97b";
}

.zso-crown:before {
  content: "\e980";
}

.zso-warning-fill:before, ._collab-type._FAILED:before {
  content: "\e982";
}

.zso-bar-chart:before, ._collab-type._REPORT:before {
  content: "\e981";
}

.zso-assign-user:before {
  content: "\e983";
}

.zso-close-ticket:before {
  content: "\e984";
}

.zso-pickup:before {
  content: "\e985";
}

.zso-markas-read:before {
  content: "\e986";
}

.zso-binoculars:before {
  content: "\e987";
}

.zso-idea-fill:before {
  content: "\e988";
}

.zso-history:before {
  content: "\e989";
}

.zso-bookmark:before {
  content: "\e98a";
}

.zso-reply:before {
  content: "\e954";
}

.zso-navigation:before {
  content: "\e98b";
}

.zso-right-arrow:before {
  content: "\e98d";
}

.zso-arrow-fancy:before, .bookmarktip div:after {
  content: "\e98e";
}

.zso-star-blend:before {
  content: "\e98f";
}

.zso-toggle-menu:before {
  content: "\e990";
}

.zso-alert:before {
  content: "\e991";
}

.zso-cancel-invite:before {
  content: "\e992";
}

.zso-newtab:before, .zs-link-viewincrm:after {
  content: "\e993";
}

.zso-info-normal:after {
  content: "\e98c";
}

.zso-infobubble:after {
  content: "\e994";
}

.zso-bulkschedule:after {
  content: "\e995";
}

.zso-hamburger:after {
  content: "\e996";
}

.tblReorder.zs-link-asc:after {
  content: "\e9b5";
}

.tblReorder.zs-link-desc:after {
  content: "\e9c9";
}

.zso-male:before {
  content: "\e94e";
  font-size: 50px;
  color: #8dcec1;
}

.zso-female:before {
  content: "\e94d";
  font-size: 50px;
  color: #c485cf;
}

.zso-megaphone-ad:before {
  content: "\e99f";
}

.zso-rebrand:before {
  content: "\e998";
}

.zso-networks:before {
  content: "\e999";
}

.zso-members:before {
  content: "\e99a";
}

.zso-creditcard:before {
  content: "\e997";
}

.zso-pause-circle:before {
  content: "\e99b";
}

.zso-play-circle:before {
  content: "\e99c";
}

.zso-resume:before {
  content: "\e99d";
}

.zso-video:before {
  content: "\e9af";
}

.zso-plane-fill:before, .instapublish:before {
  content: "\e9b0";
}

.zso-close-medium:before {
  content: "\e9a3";
}

.zso-subscription:before {
  content: "\e9bd";
}

.zso-stars:before {
  content: "\e9be";
}

.zso-edit-line:before, .zs-ico-edit:before {
  content: "\e9ad";
}

.zso-draft-icon:before {
  content: "\e964";
}

.zso-discuss-line:before {
  content: "\e9c1";
}

.zso-tickmark:before {
  content: "\e9ab";
}

.zso-trash-medium:before, .zs-ico-trash:before {
  content: "\e9ac";
}

.zso-draft-medium:before {
  content: "\e9a4";
}

.zso-user-plus:before {
  content: "\e9c3";
}

.zso-user-minus:before {
  content: "\e9c2";
}

.zso-plus-medium:before {
  content: "\e9c4";
}

.zso-reconnect:before {
  content: "\e9c8";
}

.zso-pause-medium:before {
  content: "\e9c6";
}

.zso-play:before {
  content: "\e9c7";
}

.zso-try-editions:before {
  content: "\e9bf";
}

.zso-feather:before {
  content: "\e9ca";
}

.zso-calendar-filter-line:before {
  content: "\e9b1";
}

.zso-discuss-thick:before {
  content: "\e9cc";
}

.zso-draft-thick:before {
  content: "\e9cd";
}

.zso-plane-thick:before {
  content: "\e9ce";
}

.zso-plane:before {
  content: "\e9a2";
}

.zso-trash-thick:before {
  content: "\e9cf";
}

.zso-feather:before {
  content: "\e9ca";
}

.zso-emoji-animals:before {
  content: "\e9a6";
}

.zso-emoji-clock:before {
  content: "\e9a7";
}

.zso-emoji-others:before {
  content: "\e9a8";
}

.zso-emoji-smile:before {
  content: "\e9a9";
}

.zso-emoji-symbols:before {
  content: "\e9aa";
}

.zso-emoji-food:before {
  content: "\e9f7";
}

.zso-emoji-bulb:before {
  content: "\e9f6";
}

.zso-emoji-flight:before {
  content: "\e9f4";
}

.zso-emoji-ball:before {
  content: "\e9f3";
}

.zso-emoji-symbol:before {
  content: "\e9f2";
}

.zso-emoji-flag:before {
  content: "\e9f5";
}

.zso-user-filter-line:before {
  content: "\e9b7";
}

.zso-book:before {
  content: "\e9db";
}

.zso-book:before {
  content: "\e9db";
}

.zso-gmb-btn:before {
  content: "\e9cb";
}

.zso-trynew-stars:before {
  content: "\e9dd";
}

.zso-switch-back:before {
  content: "\e9dc";
}

.zso-ticket-generation:before {
  content: "\e9df";
}

.zso-globe-line:before {
  content: "\e9e0";
}

.zso-handshake:before {
  content: "\e9e1";
}

.zso-refresh-medium:before {
  content: "\e9e2";
}

.zso-ticket-fill:before {
  content: "\e9e3";
}

.zso-ticket:before {
  content: "\e9e4";
}

.zso-addtocrm:before {
  content: "\e9e5";
}

.zso-chat:before {
  content: "\e9e6";
}

.zso-facebook:before {
  content: "\e9e7";
}

.zso-mail-thick:before {
  content: "\e9e8";
}

.zso-phone-line:before {
  content: "\e9e9";
}

.zso-twitter-thick:before {
  content: "\e9ea";
}

.zso-copy-default:before {
  content: "\e9ec";
}

.zso-add-image:before {
  content: "\e9f0";
}

.zso-youtube-publishicon:before {
  content: "\e9f9";
}

.zs-ico-dislike:before {
  content: "\e9fa";
}

.zs-ico-dislike.selected:before {
  content: "\e9f8";
}

.zs-ico-views:before, .zso-views:before {
  content: "\e9fb";
}

.zso-pinterest-publishicon:before {
  content: "\e9fc";
}

.zso-youtube-activity:before {
  content: "\e9fd";
}

.zso-youtube-likes:before {
  content: "\e9fe";
}

.zso-youtube-playlist:before {
  content: "\e9ff";
}

.zso-youtube-trending:before {
  content: "\ea00";
}

.zso-arrow-popout:before {
  content: "\ea01";
}

.zs-ico-approve:before {
  content: "\e9ab";
}

.zs-ico-flag:before {
  content: "\e9f5";
}

.zs-ico-bin:before {
  content: "\e9ac";
}

.zs-ico-block:before {
  content: "\e96b";
}

.zso-pin:before, .zs-ico-pin:before {
  content: "\e9c0";
}

.zso-boards:before {
  content: "\ea02";
}

.zso-question-bubble:after {
  content: "\ea08";
}

.zso-geolocation:after {
  content: "\ea07";
}

.zso-youtube:after {
  content: "\ea09";
}

.zso-insta-comment:after {
  content: "\ea0b";
}

.zso-zshare:after, .zs-ico-zshare:before {
  content: "\ea0a";
}

.zso-answer:after, .zs-ico-answer:before {
  content: "\ea0d";
}

.zs-ico-answer.selected:before {
  content: "\ea0c";
}

.zso-insta-comment-fill:after {
  content: "\ea0e";
}

.zso-add-media:before {
  content: "\ea0f";
}

.zso-UTM-compose:before {
  content: "\ea10";
}

.zso-url-search-box:before {
  content: "\ea13";
}

.zso-hidden:before {
  content: "\ea14";
}

.zso-sort-az:before {
  content: "\ea15";
}

.zso-sort-time:before {
  content: "\ea16";
}

.zso-add-bold:before {
  content: "\ea17";
}

.zso-down-arrow:before {
  content: "\ea18";
}

.zso-company-size:before {
  content: "\ea19";
}

.zso-phone-number:before {
  content: "\ea1a";
}

.zso-collapse:before {
  content: "\ea1b";
}

.zso-delete:before {
  content: "\ea1c";
}

.zso-grid-view:before {
  content: "\ea1d";
}

.zso-list-view:before {
  content: "\ea1e";
}

.zso-list-view-new:before {
  content: "\ea1f";
}

.zso-mark-as-read:before {
  content: "\ea20";
}

.zso-move-to-draft:before {
  content: "\ea21";
}

.zso-search-icon:before {
  content: "\ea22";
}

.zso-settings-icon:before {
  content: "\ea23";
}

.zso-visit-website:before {
  content: "\ea24";
}

.zso-write-post:before {
  content: "\ea25";
}

.zso-discuss-new:before {
  content: "\ea26";
}

.zso-edit-new:before {
  content: "\ea27";
}

.zso-video-conference:before {
  content: "\ea28";
}

.zso-video-call:before {
  content: "\ea29";
}

.zso-update:before {
  content: "\ea12";
}

.zso-exclamation-new:before {
  content: "\ea11";
}

.zso-maximize:before {
  content: "\ea2a";
}

.zso-collections:before {
  content: "\e921";
}

.zso-reset:before {
  content: "\ea10";
}

.zso-yt-addtoplaylist:before {
  content: "\ea2b";
}

.zso-yt-link:before {
  content: "\ea2c";
}

.zso-yt-plus:before {
  content: "\ea2d";
}

.zso-yt-share:before {
  content: "\ea2e";
}

.zso-alt-txt:before {
  content: "\ea2f";
}

.zso-twitter-alt-txt:before {
  content: "\ea30";
}

.zso-customize-feedback:before {
  content: "\ea31";
}

.zso-customize-minimize:before {
  content: "\ea32";
}

.zso-feedback-love:before {
  content: "\ea33";
}

.zso-announcements:before {
  content: "\ea34";
}

.zso-up-arrow:before {
  content: "\ea35";
}

.zso-media-library:before {
  content: "\ea36";
}

.zso-google-play:before {
  content: "\ea37";
}

.zso-round-tick:before {
  content: "\ea38";
}

.zso-info-new:before {
  content: "\ea39";
}

.zso-upgrade-icon:before {
  content: "\ea3a";
}

.zso-failed:before {
  content: "\ea3b";
}

.zso-clock:before {
  content: "\ea3c";
}

.zso-back-new:before {
  content: "\ea3d";
}

.zso-right-arrow-new:before {
  content: "\ea3e";
}

.zso-star-line:before {
  content: "\ea3f";
}

.zso-blog:before {
  content: "\ea40";
}

.zso-faq:before {
  content: "\ea42";
}

.zso-forum:before {
  content: "\ea44";
}

.zso-user-guide:before {
  content: "\ea45";
}

.zso-webinar:before {
  content: "\ea46";
}

.zso-premium-crown:before {
  content: "\ea47";
}

.zso-lead-forms:before {
  content: "\ea48";
}

.zso-arrow-next-new:before {
  content: "\ea49";
}

.zso-arrow-previous-new:before {
  content: "\ea4a";
}

.zso-arrow-down-new:before {
  content: "\ea4b";
}

.zso-arrow-up-new:before {
  content: "\ea4c";
}

.zso-reset:before {
  content: "\ea4d";
}

.zso-round-tick-filled:before {
  content: "\ea4e";
}

.zso-pdf:before {
  content: "\ea4f";
}

.zso-xlsx:before {
  content: "\ea50";
}

.zso-xml:before {
  content: "\ea51";
}

.zso-refresh-new:before {
  content: "\ea52";
}

.zso-twitter-line-new:before {
  content: "\ea53";
}

.zso-warning-fill-new:before {
  content: "\ea54";
}

.zso-GMB:before {
  content: "\ea55";
}

.zso-facebook-group:before {
  content: "\ea56";
}

.zso-facebook-page:before {
  content: "\ea57";
}

.zso-instagram:before {
  content: "\ea58";
}

.zso-linkedin-page:before {
  content: "\ea59";
}

.zso-linkedin-profile:before {
  content: "\ea5a";
}

.zso-twitter-profile:before {
  content: "\ea5b";
}

.zso-youtube-profile:before {
  content: "\ea5c";
}

.zso-add-member:before {
  content: "\ea5d";
}

.zso-cloud:before {
  content: "\ea5e";
}

.zso-desktop:before {
  content: "\ea5f";
}

.zso-media-library-new-post:before {
  content: "\ea60";
}

.zso-csv:before {
  content: "\ea61";
}

.zso-dropdown:before {
  content: "\ea62";
}

.zso-adjust:before {
  content: "\ea63";
}

.zso-crop_3_2:before {
  content: "\ea64";
}

.zso-crop_5_4:before {
  content: "\ea65";
}

.zso-crop_7_5:before {
  content: "\ea66";
}

.zso-crop_16_9:before {
  content: "\ea67";
}

.zso-crop_landscape:before {
  content: "\ea68";
}

.zso-crop_portrait:before {
  content: "\ea69";
}

.zso-crop-3-4:before {
  content: "\ea6a";
}

.zso-crop-4-3:before {
  content: "\ea6b";
}

.zso-crop-9-16:before {
  content: "\ea6c";
}

.zso-crop-16-9:before {
  content: "\ea6d";
}

.zso-crop-facebook:before {
  content: "\ea6e";
}

.zso-crop-free:before {
  content: "\ea6f";
}

.zso-crop-instagram:before {
  content: "\ea70";
}

.zso-crop-square:before {
  content: "\ea71";
}

.zso-crop-twitter:before {
  content: "\ea72";
}

.zso-crop:before {
  content: "\ea73";
}

.zso-filters:before {
  content: "\ea74";
}

.zso-flip-horizonntal:before {
  content: "\ea75";
}

.zso-flip-horizontal:before {
  content: "\ea76";
}

.zso-flip-vertical:before {
  content: "\ea77";
}

.zso-flip:before {
  content: "\ea78";
}

.zso-image-editor-warning:before {
  content: "\ea79";
}

.zso-redo:before {
  content: "\ea7a";
}

.zso-rotate-left:before {
  content: "\ea7b";
}

.zso-rotate-right:before {
  content: "\ea7c";
}

.zso-rotate:before {
  content: "\ea7d";
}

.zso-sticker:before {
  content: "\ea7e";
}

.zso-text-align-centre:before {
  content: "\ea7f";
}

.zso-text-align-left:before {
  content: "\ea80";
}

.zso-text-align-right:before {
  content: "\ea81";
}

.zso-text:before {
  content: "\ea82";
}

.zso-undo:before {
  content: "\ea83";
}

.zso-messenger-icon:before {
  content: "\ea84";
}

.zso-info-unfill:before {
  content: "\ea85";
}

.zso-warning-icon-round-new:before {
  content: "\ea86";
}

.zso-update-permission-token:before {
  content: "\ea87";
}

.zso-custom-cover:before {
  content: "\ea88";
}

.zso-stories-icon:before {
  content: "\ea89";
}

.zso-preview-icon:before {
  content: "\ea8a";
}

.zso-assignment-rule:before {
  content: "\ea8b";
}

.zso-download-files:before {
  content: "\ea8c";
}

.zso-pinterest:before {
  content: "\ea8d";
}

.zso-tiktok:before {
  content: "\ea8e";
}

.zso-pin-new:before {
  content: "\ea8f";
}

.zso-pin-stroke:before {
  content: "\ea8f";
}

.zso-custom-post:before {
  content: "\ea90";
}

.zso-hashtag-manager:before {
  content: "\ea91";
}

.zso-help-line:before {
  content: "\ea92";
}

.zso-ie-bgcolor:before {
  content: "\ea93";
}

.zso-igimg-tagging:before {
  content: "\ea94";
}

.zso-instagram-reels:before {
  content: "\ea95";
}

.zso-instagram-reels-fill:before {
  content: "\ea96";
}

.zso-user-tag:before {
  content: "\ea97";
}

.zso-link-new:before {
  content: "\ea98";
}

.zso-choose-date:before {
  content: "\ea99";
}

.zso-type-retweet:before {
  content: "\ea9a";
}

.zso-type-reviews:before {
  content: "\ea9b";
}

.zso-type-comment:before {
  content: "\ea9c";
}

.zso-type-heart:before {
  content: "\ea9d";
}

.zso-type-like:before {
  content: "\ea9e";
}

.zso-type-mentions:before {
  content: "\ea9f";
}

.zso-type-messages:before {
  content: "\eaa0";
}

.zso-type-reply:before {
  content: "\eaa1";
}

.zso-tick-new:before {
  content: "\eaa2";
}

.zso-thread:before {
  content: "\eaa3";
}

.zso-share-new:before {
  content: "\eaa4";
}

.zso-send:before {
  content: "\eaa5";
}

.zso-remove:before {
  content: "\eaa6";
}

.zso-pin-new:before {
  content: "\eaa7";
}

.zso-inbox-preferences:before {
  content: "\eaa8";
}

.zso-filter-line-thick:before {
  content: "\eaa9";
}

.zso-shorts:before {
  content: "\eaaa";
}

.zso-carousel-posts:before {
  content: "\eaab";
}

.zso-bulb-icon:before {
  content: "\eaac";
}

.zso-bio:before {
  content: "\eaad";
}

.zso-unassigned:before {
  content: "\eaae";
}

.zso-search-new:before {
  content: "\eaaf";
}

.zso-twitter-verified:before {
  content: "\eab0";
  color: #54aced;
}

.zso-mailwidget-mentions:before {
  content: "\eab1";
}

.zso-mailwidget-messages:before {
  content: "\eab2";
}

.zso-disassociate:before {
  content: "\eab3";
}

.zso-gmb-events:before {
  content: "\eab0";
}

.zso-disassociate:before {
  content: "\eab1";
}

.zso-mailwidget-mentions:before {
  content: "\eab2";
}

.zso-mailwidget-messages:before {
  content: "\eab3";
}

.zso-twitter-verified:before {
  content: "\eab4";
  color: #54aced;
}

.zso-comments-line:before {
  content: "\eab5";
}

.zso-mentions-line:before {
  content: "\eab6";
}

.zso-messages-line:before {
  content: "\eab7";
}

.zso-post-by-others-line:before {
  content: "\eab8";
}

.zso-reply-line-new:before {
  content: "\eab9";
}

.zso-instagram-grid-preview:before {
  content: "\eaba";
}

.zso-followers:before {
  content: "\eabb";
}

.zso-choose-frame:before {
  content: "\eabc";
}

.zso-export-new:before {
  content: "\eabd";
}

.zso-reports-view-by:before {
  content: "\eabe";
}
