@import "./zso.scss";


body {
  overflow-x: hidden;
}

#root {
  position: relative;
  min-height: 100%;
}

#layout-wrapper {
  position: relative;
  height: 100%;
}

.main-content {
  height: 100%;
  min-height: 100vh;
}

.font-bold {
  font-weight: bold;
}

.logo-md {
  height: 4.5rem;
  width: 9.5rem;
}

.ant-popover.ant-popconfirm {
  z-index: 999999;
}

.rating-component .wrap-container {
  justify-content: left;

  .rating-container {
    font-size: unset !important;
  }
}

.flex-direction-column {
  flex-direction: column;
}

.cursor-pointer {
  cursor: pointer;
}

.card {
  border: 1px solid #e5e5e5;

  .card-header {
    color: black;
    font-weight: 500;
    font-size: 16px;
  }
}

.card-body {
  // border: 1px solid rgba(5, 5, 5, 0.12);
  // border-radius: 7px;
}

.cnt.ant-btn .anticon {
  transform: translateY(-3px);
}

.offcanvas-header {
  border-bottom: 1px solid #e6e6e6;
}

.navbar-header {
  background-color: #143668;

  .header-item {
    color: white;

    &:hover {
      color: white;
    }
  }
}

.cst-footer {

  position: fixed;
  width: 100%;
  background-color: #fff;
  bottom: 0px;
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  padding: 5px 10px;
}

.overflow-hidden {
  overflow: hidden;
}


.table-light {
  th {
    background-color: #f5f5f5;
  }
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}


/* CARD VALUE */


.text-muted.fw-medium {
  font-size: 1rem;
  color: black !important;
  text-transform: uppercase;


  &+h4 {
    font-size: 3.6rem;
    color: #397eaf;

    span {
      font-size: 23px;
      position: relative;
      i {
        top: -14px;
        font-size: 15px;
      }
    }
  }
}

/* END CARD VALUE */


/* POST START */
.post {
  &-container {
    display: flex;
  }

  &-image {
    margin-right: 5px;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 7px;
    }
  }

  &-content {
    height: 40px;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

}

.post-add-canvas {
  width: min(800px, 100vw);
  left: 50% !important;
  height: fit-content !important;
  transform: translateX(-50%) !important;

  .offcanvas-body {
    padding: 0px;
  }

  .row {
    margin-left: unset;
    margin-right: unset;
  }

  .ant-upload.ant-upload-select {
    display: none;
  }
}

.post-left-section {
  position: relative;
  padding: 20px 10px;
}

.post-right-section {
  position: relative;
  height: 100%;
  border-left: 1px solid rgba(125, 125, 125, 0.5);

  &-header {
    background-color: #f3f1f1;
    font-size: 1rem;
    color: black;
    padding: 10px 5px;
  }

  &-content {
    padding: 10px 5px;
  }

  &-radio {
    padding: 10px 10px;
  }
}

.post-footer {
  background-color: #f3f1f1;
  border-top: 1px solid rgba(125, 125, 125, 0.5);
  display: flex;
  padding: 15px 10px;

  &-left {
    display: flex;
    flex-grow: 1;
    align-items: center;

    i {
      margin: 0px 10px;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.2);
      }
    }

    &-url {
      display: flex;
      padding-left: 20px;
      align-items: center;
      gap: 5px;
    }

    &-radio {
      padding: 5px 0px;
    }
  }
}

/* POST END */

/* SUB Navigation START */
.sub-navigation {
  background-color: #ffffffff;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.12);
  padding-right: 0px;
  padding-left: 0px;
  min-height: calc(100vh - 138px);
  position: fixed;
  transform: translateX(-6px);
  height: 200px;
  overflow-y: auto;
  z-index: 99;

  &.post-page {
    min-height: calc(100vh - 87px);
  }

  .sub-navigation-container {
    position: fixed;
    width: 100%;
    top: 0px;
    overflow-y: auto;
  }

  .ant-menu {
    position: fixed;
    background: transparent;
    // width: fit-content;

    // .ant-menu-item {
    //     width: fit-content;
    // }
  }
}

.sub-content {
  padding-top: 20px;
  padding-left: 30px;
}

/* SUB Navigation END */

/* SUB HEADER START */
.sub-header-container {
  height: 53px;
  z-index: 99;
}

.sub-header {
  padding-right: 25px;
  position: fixed;
  background-color: white;
  display: flex;
  justify-content: space-between;
  // padding: 10px 0px;
  margin-bottom: 0px;
  width: calc(100% + 35px);
  height: fit-content;
  transform: translateX(-18px);
  border-bottom: 1px solid rgba(5, 5, 5, 0.12);

  &-right {
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }

  &-left {
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }

  &-round-button {
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #f3f1f1;
    border: 1px solid rgba(5, 5, 5, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    transition: all ease-in-out 0.3s;
    color: #808080;

    &.active {
      color: unset;
      background-color: rgb(213, 213, 255);
    }

    &:hover {
      color: #1677FF;
      background-color: rgb(213, 213, 255);
    }
  }

  &-button {
    padding: 10px 10px;
    // &::after {
    //     content: "";
    //     position: absolute;
    //     height: 100%;
    //     width: 2px;
    //     background-color: red;
    //     right: 0px;
    // }
    border-right: 1px solid rgba(5, 5, 5, 0.12);

    &:first-of-type {
      border-left: 1px solid rgba(5, 5, 5, 0.12);
    }
  }
}

/* SUB HEADER END */


.btn.selected {
  position: relative;
  font-weight: 500;
  z-index: 9;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 20px;
    z-index: -1;
  }

}

/* CARD CONTAINER */
.card-container {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  padding: 8px 20px 8px 15px;
  transition: all 0.3s;
  cursor: pointer;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;

  &:hover {
    background-color: #F9F9F9;

    & .card-content {
      padding-left: 5px;
    }

    & .card-icon-add {
      display: flex;
    }
  }

  .card-content {
    transition: all 0.3s;
    display: flex;
    align-items: center;
    font-size: 13px;


  }

  .card-icon {
    i {
      font-size: 20px;
    }

    &-add {
      display: none;
      border: 1px solid black;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }

    &-check {
      display: none;
      color: green;
    }

    &-delete {
      display: none;
      color: red;
    }
  }

  &.checked {

    background-color: #f5f5f5;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;

    .card-icon {
      &-add {
        display: none;
      }

      &-check {
        display: block;
      }

      &-delete {
        display: none;
      }
    }

    &:hover {

      .card-icon {

        &-check {
          display: none;
        }

        &-delete {
          display: block;
        }
      }

    }
  }

}

/* CARD CONTAINER */
/* START: SCHEDULE UI */

.email-select {
  .ant-select-selection-overflow {
    display: flex;
    align-content: start;
    justify-content: start;
    min-height: 90px;
  }

}

.schedule-footer {
  display: flex;
  flex-direction: row-reverse;
  padding: 10px 10px;
  background-color: #f3f1f1;
  border: 1px solid rgba(5, 5, 5, 0.12);


  button {
    max-width: fit-content;
  }
}

/* END: SCHEDULE UI */

/* START: CUSTOM CARD HEADER */
.custom-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-card-name-edit {
  font-size: 14px;
  margin-left: 10px;
  position: absolute;
  top: -5px;
  cursor: pointer;
}

.center-xy {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* END: CUSTOM CARD HEADER */

/* START: CUSTOM MENU */

.cst-menu {

  .cst-menu-header {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
      font-size: 16px;
      font-weight: 500;
    }

    &-icon {
      display: flex;
      cursor: pointer;
      font-size: 17px;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      align-items: center;
      padding: 10px 5px 10px 15px;
      font-size: 14px;
      cursor: pointer;

      i,
      svg {
        margin-right: 5px;
      }

      &.active {
        background-color: rgba(0, 0, 0, 0.06);
        border-left: 5px solid #f39449;
        padding-left: 10px;
        box-shadow: 0px -1px 1px 0 rgb(0 0 0 / 3%) inset;
        font-weight: 500;
      }

      &:hover {
        background-color: #f4f4f4;
      }
    }
  }
}

/* END: CUSTOM MENU */

/* START: EMPTY CARD */
.empty-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  gap: 10px;

  &-img {}

  &-text {
    font-size: 18px;
    font-weight: 500;
  }
}

/* END: EMPTY CARD */


/*
 *  STYLE 4
 */

// .sub-navigation::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   background-color: #F5F5F5;
// }

// .sub-navigation::-webkit-scrollbar {
//   width: 10px;
//   background-color: #F5F5F5;
// }

// .sub-navigation::-webkit-scrollbar-thumb {
//   background-color: #000000;
//   border: 2px solid #555555;
// }

.sub-navigation {

  &::-webkit-scrollbar {
    width: 0px;
    transition: all;
  }

  &:hover {
    scrollbar-width: auto;
    scrollbar-color: #808080 #ffffff;

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #808080;
      border-radius: 10px;
      border: 3px solid #ffffff;
    }
  }
}


.empty-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 365px;

  img {
    height: 200px;
  }
}

.mini-stats-wid {
  .card-body {
    // padding: 5px;
  }
}

.print-report {
  &-header {
    padding-bottom: 5px;
    border-bottom: 2px solid black;
  }

  &-header-second {
    margin-bottom: 15px;
  }
}